import React, { useState } from 'react'

import { classnames } from 'begonya/functions'

import { useWidth } from '@/core/hooks/useWidthResize'
import { useApp } from '@/core/contexts/app'

import { Column, Container, Row } from '@/components/base/gridview'
import Image from '@/components/base/image'
import Anchor from '@/components/base/anchor/Anchor'
import Icon from '@/components/base/icon'

import Popup from '../popup'
import Navigation from './Navigation'

import { HeaderProps } from './types'

const Header: React.FunctionComponent<HeaderProps> = ({
  logo,
  navigation,
  isHome,
}) => {
  const app = useApp()
  const width = useWidth()

  const [panel, setPanel] = useState('')

  return (
    <React.Fragment>
      <Popup />
      <header className={classnames('header', isHome && 'header--home')}>
        <div className="header-body">
          <Container size="extended">
            <Row
              xs={{
                justify: `${width >= 1025 ? 'between' : 'center'}`,
              }}
            >
              <Column>
                <Anchor
                  className="header-body-logo"
                  href={app.settings.homepage}
                >
                  <Image
                    fluid
                    {...(logo?.src ? logo : app.theme.logo)}
                    alt={app.page.title}
                  />
                </Anchor>
              </Column>
              {width > 1025 && (
                <Column>
                  <nav className="header-body-menu">
                    <ul>
                      {navigation?.map((menuItem, index) => (
                        <li
                          key={index}
                          className={classnames(menuItem.className)}
                        >
                          <Anchor
                            href={menuItem.href}
                            dangerouslySetInnerHTML={{
                              __html: menuItem.title,
                            }}
                          />
                        </li>
                      ))}
                    </ul>
                  </nav>
                </Column>
              )}
              {width <= 1025 && (
                <button
                  className="desktop-menu-button"
                  onClick={() => setPanel('menu')}
                >
                  <Icon name="icon-menu" size="medium" />
                </button>
              )}
            </Row>
          </Container>
          {navigation ? (
            <Navigation
              isOpen={panel === 'menu'}
              data={navigation}
              onClose={() => setPanel('')}
              logo={logo}
            />
          ) : null}
        </div>
      </header>
    </React.Fragment>
  )
}

export default Header
