import React from 'react'

import { classnames } from 'begonya/functions'

import Anchor from '@/components/base/anchor'
import { Column, Row } from '@/components/base/gridview'

import Panel from './Panel'

import { NavigationProps } from './types'

const Navigation: React.FunctionComponent<NavigationProps> = ({
  data,
  isOpen,
  logo,
  onClose,
}) => {
  return (
    <Panel onClose={onClose} isOpen={isOpen} logo={logo}>
      <div className="navigation">
        <Row>
          <Column>
            <nav className="navigation-menu">
              <ul>
                {data.map((menuItem, index) => (
                  <li
                    key={index}
                    className={classnames(
                      menuItem.className,
                      menuItem.children.length && 'hasSubMenu'
                    )}
                  >
                    <Anchor
                      onClick={onClose}
                      className="menu-item"
                      href={menuItem.href}
                      dangerouslySetInnerHTML={{ __html: menuItem.title }}
                    />
                  </li>
                ))}
              </ul>
            </nav>
          </Column>
        </Row>
      </div>
    </Panel>
  )
}

export default Navigation
