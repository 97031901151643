import React, { useEffect, useRef, useState } from 'react'

import { usePopup } from '@/core/contexts/popup'

import Button from '@/components/base/button'
import Icon from '@/components/base/icon'
import Video from '@/components/base/video'
import { classnames } from 'begonya/functions'

import { PopupProps } from './types'
import Image from '@/components/base/image'

const Popup: React.FunctionComponent<PopupProps> = () => {
  const { popup, setPopup } = usePopup()
  const { popupData, setPopupData } = usePopup()
  const { queryParams, setQueryParams } = usePopup()

  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (popup) {
      const newUrl = window.location.pathname + `?url=${queryParams.url}`
      window.history.pushState({}, '', newUrl)
    }
  }, [popup, setPopup])

  const closeHandle = () => {
    const videoElement = videoRef.current
    if (videoElement) {
      videoElement.pause()
    }

    setPopup(false)

    window.history.pushState({}, '', window.location.pathname)

    setQueryParams({
      url: '',
    })

    setPopupData({
      title: '',
      desc: '',
      image: '',
      video: '',
    })

    setPopup(false)
  }

  return (
    <div
      className={classnames(
        'popup',
        popup && 'popup--active',
        popupData.video && 'popup--video'
      )}
    >
      <div className="popup-content">
        <Button onClick={closeHandle} className="popup-close">
          x
        </Button>
        {popupData.video ? (
          <Video
            ref={videoRef}
            src={popupData.video}
            controls
            autoPlay={true}
          />
        ) : (
          <React.Fragment>
            <Image fluid src={popupData.image} alt={popupData.title} />
            <div className="detail">
              <div className="title">{popupData.title}</div>
              {popupData.desc && (
                <div dangerouslySetInnerHTML={{ __html: popupData.desc }} />
              )}
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default Popup
