import React, { useEffect } from 'react'
import { useRouter } from 'next/router'

import { useApp } from '@/core/contexts/app'

import Anchor from '@/components/base/anchor'
import { Column, Row } from '@/components/base/gridview'
import Icon from '@/components/base/icon'
import Image from '@/components/base/image'

import { PanelProps } from './types'

const Panel: React.FunctionComponent<PanelProps> = ({
  children,
  isOpen,
  logo,
  onClose,
}) => {
  const app = useApp()
  const router = useRouter()

  return (
    <div className={`panel panel--${isOpen ? 'show' : 'hide'}`}>
      <Row xs={{ align: 'center', justify: 'between' }}>
        <Column auto>
          <Anchor
            href={app.settings.homepage}
            onClick={onClose}
            className="panel-logo"
          >
            <Image
              {...(logo?.src ? logo : app.theme.logo)}
              alt={app.page.title}
              height={75}
            />
          </Anchor>
        </Column>
        <Column auto className="panel-action">
          <button className="menu-button panel-close-button" onClick={onClose}>
            <Icon name="icon-clear" size="medium" />
          </button>
        </Column>
      </Row>
      {children}
    </div>
  )
}

export default Panel
